@mixin adhere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin transition($property, $duration: time(), $timing-function: ease()) {
  transition: {
    property: $property;
    duration: $duration;
    timing-function: $timing-function;
  }
}

$breakpoints: (
  xsmall: 0,
  small: 360px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

@function bp($key) {
  @return map-get($breakpoints, $key);
}

@mixin breakpoint($size) {
  @media (min-width: bp($size)) {
    @content;
  }
}

@mixin grid($gap: 10px) {
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  grid-gap: $gap;
  max-width: 1200px;
  @include breakpoint(xlarge) {
    margin-right: auto;
    margin-left: auto;
  }
}
