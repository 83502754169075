.Home {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.Home__inner {
  position: relative;
  @include grid;
  grid-template-areas:
    "heading"
    "links"
    "picture";
  @include breakpoint(large) {
    grid-template-areas:
      "heading heading"
      "links picture";
    grid-template-columns: 1fr 2fr;
  }
}

.Home__header {
  grid-area: heading;
  padding-bottom: 20px;
  @include breakpoint(large) {
    padding-bottom: 40px;
  }
}

.Home__heading {
  @include heading-1;
  color: $dark-grey;
}

.Home__copy {
  @include body-1;
  color: $dark-grey;
  margin-top: 20px;
}

.Home__linkList {
  grid-area: links;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.Home__linkListItem {
  & + & {
    margin-top: 40px;
  }
}

.Home__link {
  @include button-primary;
}

.Home__imageWrapper {
  position: relative;
  width: 100%;
  height: 400px;
}

.Home__image {
  @include adhere;
  object-fit: cover;
}
