.AppLinkPrimary {
  position: relative;
  display: block;
  // max-width: 50vw;
  // padding: 5px 10px;
  // background-color: orangered;
  // color: white;
  // border: 1px solid black;
  // text-align: center;

  // &:hover,
  // &:focus {
  //   color: orangered;
  //   background-color: white;

  // }
}

.AppButton {
  font-size: 16px;
  background-color: $white;
  border: 1px solid black;
  border-radius: 0;
  padding: 5px 10px;
  margin-top: 20px;
  width: 100%;
}
