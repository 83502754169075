.PlayView {
  // position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.PlayView__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}

.PlayView__bgImage {
  @include adhere;
  object-fit: cover;
}

.PlayView__inner {
  position: relative;
  @include grid;
  grid-template-areas:
    "heading"
    "form"
    "play"
    "dust";

  @include breakpoint(large) {
    grid-template-areas:
      "heading heading"
      "form play"
      "dust dust";
    grid-template-columns: 2fr 3fr;
  }
}

.PlayView__header {
  grid-area: heading;
  padding-top: 20px;
  padding-bottom: 20px;
}

.PlayView__heading {
  @include heading-1;
  color: $dark-grey;
}

.PlayView__body {
  @include body-1;
  color: $dark-grey;
  margin-top: 10px;
}
.PlayView__cone {
  width: 20px;
}

.PlayView__forms {
  grid-area: form;
}

.PlayView__formContent {
}

.PlayView__formSubmit {
  @include button-secondary;
}

.PlayView__play {
  grid-area: play;
  display: flex;
  flex-direction: column;
  background-color: $tan;
  padding: 10px;
  border: 2px solid black;
}

.PlayView__title {
  @include heading-2;
}

.PlayView__entry {
  margin-top: 5px;
  @include body-2;
}

.error-border {
  border-color: red;
}

.highlight {
  color: red;
}
