* {
  padding: 0;
  margin: 0;
  // font-family: monospace;
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
