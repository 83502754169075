.Sonnet {
  position: relative;
  background: radial-gradient(black 10%, transparent 70%);
  padding-top: 10px;
}

$paper-edge: polygon(
  100% 100%,
  100% 22%,
  90% 8%,
  80% 15%,
  74% 29%,
  68% 32%,
  63% 34%,
  60% 20%,
  53% 11%,
  44% 17%,
  39% 31%,
  32% 36%,
  20% 19%,
  6% 13%,
  0% 10%,
  0 100%
);

.Sonnet__accentTop {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  clip-path: $paper-edge;
  background-color: $dark-grey;

  &::before {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 4px);
    background-color: rgb(230, 222, 213);
    clip-path: $paper-edge;
    content: "";
  }
}

.Sonnet__paper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-top-right-radius: 4px 10px;
  border-bottom-right-radius: 10px;
  background-color: $dark-grey;

  &::before {
    position: absolute;
    top: 0;
    left: 4px;
    width: calc(100% - 8px);
    height: 100%;
    background-color: $tan;
    content: "";
  }
}

.Sonnet__textList {
  position: relative;
}

.Sonnet__line {
  margin-top: 6px;
  @include body-2;
  color: $dark-grey;
}

.Sonnet__line--ending {
  padding-left: 10px;
}

.Sonnet__accentBottom {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  clip-path: $paper-edge;
  background-color: $dark-grey;
  transform: rotate(180deg);

  &::before {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 4px);
    background-color: rgb(230, 222, 213);
    clip-path: $paper-edge;
    content: "";
  }
}
