.AppInput {
  width: 100%;
  border: 0px solid transparent;
  border-bottom: {
    width: 2px;
    style: solid;
  }
  height: 30px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, $black, $green);
  background-color: rgba(255,255,255, .2);
  @include body-2;
  color: $grey
}
