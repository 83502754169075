@import url(https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300;400;700&family=Roboto+Slab:wght@200;300;400;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.SonnetView {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.SonnetView__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}

.SonnetView__bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SonnetView__inner {
  position: relative;
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  grid-gap: 10px;
  max-width: 1200px;
  grid-template-areas: "heading" "form" "sonnet" "dust";
}
@media (min-width: 1200px) {
  .SonnetView__inner {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1024px) {
  .SonnetView__inner {
    grid-template-areas: "heading heading" "form sonnet" "dust dust";
    grid-template-columns: 2fr 3fr;
  }
}

.SonnetView__header {
  grid-area: heading;
  padding-top: 20px;
  padding-bottom: 20px;
}

.SonnetView__heading {
  font-family: "MuseoModerno", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.2rem;
  color: #373124;
}

.SonnetView__body {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 300;
  color: #373124;
  margin-top: 10px;
}

.SonnetView__forms {
  grid-area: form;
}

.SonnetView__form {
  min-width: 150px;
}

.SonnetView__formButton {
  font-family: "MuseoModerno", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  background-color: #fcfdf6;
  border: 2px solid #1583E4;
  color: #373124;
  border-top-left-radius: 15px 40%;
  border-top-right-radius: 15px 40%;
  padding: 5px 10px;
  margin-top: 20px;
  transition-property: background-color, color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.SonnetView__formButton:hover, .SonnetView__formButton:focus {
  background-color: #1583E4;
  color: #fcfdf6;
}

.SonnetView__or {
  font-family: "MuseoModerno", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #1583E4;
  margin-top: 20px;
}

.SonnetView__sonnet {
  grid-area: sonnet;
}

.SonnetView__sonnetButton {
  font-family: "MuseoModerno", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  background-color: #fcfdf6;
  border: 2px solid #1583E4;
  color: #373124;
  border-top-left-radius: 15px 40%;
  border-top-right-radius: 15px 40%;
  padding: 5px 10px;
  margin-top: 20px;
  transition-property: background-color, color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.SonnetView__sonnetButton:hover, .SonnetView__sonnetButton:focus {
  background-color: #1583E4;
  color: #fcfdf6;
}

@media (min-width: 640px) {
  .SonnetView__sonnetInner {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.SonnetView__sonnetTitle {
  font-family: "MuseoModerno", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.SonnetView__sonnetButtons {
  display: flex;
  justify-content: space-between;
}

.error-border {
  border-color: red;
}

.highlight {
  color: red;
}

.Home {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.Home__inner {
  position: relative;
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  grid-gap: 10px;
  max-width: 1200px;
  grid-template-areas: "heading" "links" "picture";
}
@media (min-width: 1200px) {
  .Home__inner {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1024px) {
  .Home__inner {
    grid-template-areas: "heading heading" "links picture";
    grid-template-columns: 1fr 2fr;
  }
}

.Home__header {
  grid-area: heading;
  padding-bottom: 20px;
}
@media (min-width: 1024px) {
  .Home__header {
    padding-bottom: 40px;
  }
}

.Home__heading {
  font-family: "MuseoModerno", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.2rem;
  color: #373124;
}

.Home__copy {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 300;
  color: #373124;
  margin-top: 20px;
}

.Home__linkList {
  grid-area: links;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.Home__linkListItem + .Home__linkListItem {
  margin-top: 40px;
}

.Home__link {
  position: relative;
  font-family: "MuseoModerno", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  display: inline-block;
  border-top-left-radius: 15px 40%;
  border-top-right-radius: 15px 40%;
  color: #fcfdf6;
  padding-top: 5px;
  padding-right: 55px;
  padding-bottom: 5px;
  padding-left: 20px;
  background-color: #1583E4;
}
.Home__link::before {
  position: absolute;
  top: 50%;
  right: 28px;
  width: 2px;
  height: 25px;
  background-color: #fcfdf6;
  content: "";
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Home__link::after {
  position: absolute;
  top: 15px;
  right: 28px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 15px solid #fcfdf6;
  border-bottom: 5px solid transparent;
  content: "";
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.Home__link:hover, .Home__link:focus {
  text-decoration: underline;
}
.Home__link:hover::after, .Home__link:focus::after {
  -webkit-transform: translateX(100%) rotateY(180deg);
          transform: translateX(100%) rotateY(180deg);
}

.Home__imageWrapper {
  position: relative;
  width: 100%;
  height: 400px;
}

.Home__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PlayView {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.PlayView__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}

.PlayView__bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PlayView__inner {
  position: relative;
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  grid-gap: 10px;
  max-width: 1200px;
  grid-template-areas: "heading" "form" "play" "dust";
}
@media (min-width: 1200px) {
  .PlayView__inner {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1024px) {
  .PlayView__inner {
    grid-template-areas: "heading heading" "form play" "dust dust";
    grid-template-columns: 2fr 3fr;
  }
}

.PlayView__header {
  grid-area: heading;
  padding-top: 20px;
  padding-bottom: 20px;
}

.PlayView__heading {
  font-family: "MuseoModerno", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.2rem;
  color: #373124;
}

.PlayView__body {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 300;
  color: #373124;
  margin-top: 10px;
}

.PlayView__cone {
  width: 20px;
}

.PlayView__forms {
  grid-area: form;
}

.PlayView__formSubmit {
  font-family: "MuseoModerno", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  background-color: #fcfdf6;
  border: 2px solid #1583E4;
  color: #373124;
  border-top-left-radius: 15px 40%;
  border-top-right-radius: 15px 40%;
  padding: 5px 10px;
  margin-top: 20px;
  transition-property: background-color, color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.PlayView__formSubmit:hover, .PlayView__formSubmit:focus {
  background-color: #1583E4;
  color: #fcfdf6;
}

.PlayView__play {
  grid-area: play;
  display: flex;
  flex-direction: column;
  background-color: #e6ded5;
  padding: 10px;
  border: 2px solid black;
}

.PlayView__title {
  font-family: "MuseoModerno", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.PlayView__entry {
  margin-top: 5px;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05rem;
}

.error-border {
  border-color: red;
}

.highlight {
  color: red;
}

.AppLinkPrimary {
  position: relative;
  display: block;
}

.AppButton {
  font-size: 16px;
  background-color: #fcfdf6;
  border: 1px solid black;
  border-radius: 0;
  padding: 5px 10px;
  margin-top: 20px;
  width: 100%;
}

.AppInput {
  width: 100%;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  height: 30px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #000, #27A45F);
  background-color: rgba(255, 255, 255, 0.2);
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05rem;
  color: #746B57;
}

.Sonnet {
  position: relative;
  background: radial-gradient(black 10%, transparent 70%);
  padding-top: 10px;
}

.Sonnet__accentTop {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  -webkit-clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
          clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
  background-color: #373124;
}
.Sonnet__accentTop::before {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: #e6ded5;
  -webkit-clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
          clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
  content: "";
}

.Sonnet__paper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-top-right-radius: 4px 10px;
  border-bottom-right-radius: 10px;
  background-color: #373124;
}
.Sonnet__paper::before {
  position: absolute;
  top: 0;
  left: 4px;
  width: calc(100% - 8px);
  height: 100%;
  background-color: #e6ded5;
  content: "";
}

.Sonnet__textList {
  position: relative;
}

.Sonnet__line {
  margin-top: 6px;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05rem;
  color: #373124;
}

.Sonnet__line--ending {
  padding-left: 10px;
}

.Sonnet__accentBottom {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  -webkit-clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
          clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
  background-color: #373124;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Sonnet__accentBottom::before {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: #e6ded5;
  -webkit-clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
          clip-path: polygon(100% 100%, 100% 22%, 90% 8%, 80% 15%, 74% 29%, 68% 32%, 63% 34%, 60% 20%, 53% 11%, 44% 17%, 39% 31%, 32% 36%, 20% 19%, 6% 13%, 0% 10%, 0 100%);
  content: "";
}

.Stage {
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  grid-gap: 0px;
  max-width: 1200px;
  margin-right: 0px;
  margin-left: -100px;
  max-width: 800px;
  grid-template-areas: "valence valence" "left form ";
  grid-template-columns: 1fr 3fr;
}
@media (min-width: 1200px) {
  .Stage {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 640px) {
  .Stage {
    grid-template-areas: "valence valence valence" "left form right";
    margin-left: 0px;
    grid-template-columns: 1fr 3fr 1fr;
  }
}

.Stage__valenceWrap {
  grid-area: valence;
  width: 100%;
  z-index: 1;
}

.Stage__valenceUpper {
  border-bottom-left-radius: 40px 100%;
  border-bottom-right-radius: 40px 100%;
  width: 100%;
  height: 25px;
  background-image: linear-gradient(#373124, #A30F0F);
}

.Stage__valenceLower {
  border-bottom-left-radius: 40px 100%;
  border-bottom-right-radius: 40px 100%;
  width: calc(100% - 80px);
  height: 10px;
  margin-right: auto;
  margin-left: auto;
  background-color: #A30F0F;
  border-bottom: 2px solid #C7A20E;
}

.Stage__leftColumn {
  grid-area: left;
  justify-self: start;
}

.Stage__form {
  position: relative;
  grid-area: form;
  margin-top: 50px;
  padding-right: 100px;
  min-width: 150px;
}
@media (min-width: 640px) {
  .Stage__form {
    padding-right: 0;
  }
}

@media (min-width: 640px) {
  .Stage__formInner {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% + 80px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.Stage__buttonWrap {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Stage__rightColumn {
  grid-area: right;
  justify-self: end;
}

.Stage__column {
  position: relative;
  display: block;
  width: 170px;
  overflow-x: hidden;
  margin-left: 60px;
}

.Stage__column--small {
  display: none;
}
@media (min-width: 640px) {
  .Stage__column--small {
    display: block;
    -webkit-transform: scale(0.7, 0.8);
            transform: scale(0.7, 0.8);
    -webkit-transform-origin: top;
            transform-origin: top;
    margin-right: 40px;
    margin-left: auto;
  }
}

.Stage__buttressWrap {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

.Stage__buttress {
  position: relative;
  width: 100px;
  height: 100px;
  border-top-right-radius: 50% 50%;
  background-color: #746B57;
  -webkit-transform: translate(5px, -5px);
          transform: translate(5px, -5px);
  flex-shrink: 0;
  -webkit-clip-path: polygon(35% 6%, 20% 3%, 0 0, 100% 0, 100% 100%, 98% 81%, 94% 65%, 88% 51%, 77% 36%, 65% 24%, 52% 14%);
          clip-path: polygon(35% 6%, 20% 3%, 0 0, 100% 0, 100% 100%, 98% 81%, 94% 65%, 88% 51%, 77% 36%, 65% 24%, 52% 14%);
}
.Stage__buttress::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 100% 100%;
  background-color: transparent;
  border-top: 2px solid #F5CD2D;
  border-right: 2px solid #F5CD2D;
  content: "";
}

.Stage__butress--right {
  -webkit-transform: scaleX(-1) translate(5px, -5px);
          transform: scaleX(-1) translate(5px, -5px);
}

.Stage__columnTop {
  position: relative;
  display: inline-block;
  z-index: 1;
  height: 100px;
  width: 37px;
  background-color: #373124;
  flex-shrink: 0;
}
.Stage__columnTop::after {
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 130%;
  height: 0;
  border-top: 30px solid #C7A20E;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  -webkit-transform: translate(50%, 100%);
          transform: translate(50%, 100%);
}

.Stage__buttressCenter {
  position: relative;
  width: 17px;
  height: 75px;
  margin: 0 auto;
  padding-top: 20px;
  background-image: linear-gradient(#9F9792, #746B57);
  border-right: 1px solid #F5CD2D;
  border-left: 1px solid #F5CD2D;
}

.Stage__buttressCenterAccent {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 50% 50% 0 50%;
  background-color: #9F9792;
  -webkit-transform: rotate(45deg) skewY(30deg);
          transform: rotate(45deg) skewY(30deg);
}

.Stage__columnImageWrapper {
  position: relative;
  width: 55px;
  height: 250px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  border-top-right-radius: 5px 150px;
  border-top-left-radius: 5px 150px;
}

.Stage__columnImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right top;
}

.Stage__columnBase {
  display: flex;
  justify-content: center;
  flex: 0 0;
}

.Stage__columnBaseSide {
  display: inline-block;
}
.Stage__columnBaseSide:nth-child(1) {
  -webkit-transform: matrix(0.5, 0.7, 0, 1, 8.5, -11);
          transform: matrix(0.5, 0.7, 0, 1, 8.5, -11);
}
.Stage__columnBaseSide:nth-child(1) .Stage__columnBaseTopWing::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 5px solid #746B57;
  border-left: 5px solid #746B57;
  -webkit-transform: translateX(-85%);
          transform: translateX(-85%);
  content: "";
}
.Stage__columnBaseSide:nth-child(3) {
  -webkit-transform: matrix(0.5, -0.7, 0, 1, -8.5, -11);
          transform: matrix(0.5, -0.7, 0, 1, -8.5, -11);
}
.Stage__columnBaseSide:nth-child(3) .Stage__columnBaseTopWing::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 5px solid #746B57;
  border-right: 5px solid #746B57;
  -webkit-transform: translateX(85%);
          transform: translateX(85%);
  content: "";
}

.Stage__columnBaseTopGold {
  height: 5px;
  background-color: #C7A20E;
}

.Stage__columnBaseTopBlock {
  height: 8px;
  background-color: #9F9792;
}

.Stage__columnBaseTopWing {
  position: relative;
  height: 5px;
  background-color: #746B57;
}

.Stage__columnBasePlate {
  padding: 9px 6px;
  background-color: #9F9792;
  background-image: linear-gradient(#9F9792 80%, #746B57);
}

.Stage__columnAccentBorder {
  display: inline-block;
  padding: 1px;
  background-color: #746B57;
  border: 1px solid #A30F0F;
}

.Stage__columnAccent {
  width: 0;
  height: 50px;
  border-top: 8px solid #58ABA3;
  border-right: 8px solid #27A45F;
  border-bottom: 8px solid #2F7951;
  border-left: 8px solid #58AB7B;
}

.Footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.Header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
}

.Navigation {
  position: relative;
}

.Navigation__accents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(90deg, #fcfdf6, #fcfdf6, 50px, #746B57 50px, #746B57 52px);
  content: "";
}
.Navigation__accents::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #746B57;
  content: "";
  z-index: 1;
}
.Navigation__accents::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #746B57;
  content: "";
}

@media (min-width: 640px) {
  .Navigation__accentLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 75px solid #746B57;
    border-right: 75px solid transparent;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .Navigation__accentLeft::after {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 71px solid #fcfdf6;
    border-right: 71px solid transparent;
    content: "";
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

@media (min-width: 640px) {
  .Navigation__accentRight {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 75px solid #746B57;
    border-left: 75px solid transparent;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .Navigation__accentRight::after {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 71px solid #fcfdf6;
    border-left: 71px solid transparent;
    content: "";
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

.Navigation__listItem {
  background-color: #fcfdf6;
}

.Navigation__list {
  position: relative;
  display: flex;
  justify-content: right;
}

.Navigation__link {
  font-family: "MuseoModerno", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #373124;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 2px solid #746B57;
  background-color: #fcfdf6;
  width: 50px;
  height: 50px;
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.Navigation__link:hover, .Navigation__link:focus {
  background-color: rgba(199, 162, 14, 0.1);
  text-decoration: underline;
}

.Navigation__link--arch {
  min-width: 80px;
  border-top-left-radius: 30% 90%;
  border-top-right-radius: 30% 90%;
  padding: 15px 5px;
}

.Navigation__homePlaceHolder {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #C91517;
}

.Title {
  display: flex;
  justify-content: flex-end;
  margin: 10px 30px;
}
.Title h1 {
  font-family: "MuseoModerno", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  position: relative;
  color: #fcfdf6;
}

.Title__wrapper {
  position: relative;
  padding: 10px 30px;
}

.Title__scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1583E4;
  -webkit-transform: skewX(25deg);
          transform: skewX(25deg);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.Title__wrapper:hover .Title__scroll, .Title__wrapper:focus .Title__scroll {
  -webkit-transform: skewX(20deg);
          transform: skewX(20deg);
}
