.SonnetView {
  // position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}

.SonnetView__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}

.SonnetView__bgImage {
  @include adhere;
  object-fit: cover;
}

.SonnetView__inner {
  position: relative;
  @include grid;
  grid-template-areas:
   "heading"
    "form"
    "sonnet"
    "dust";

  @include breakpoint(large) {
    grid-template-areas:
      "heading heading"
      "form sonnet"
      "dust dust";
    grid-template-columns: 2fr 3fr;
  }
}
.SonnetView__header {
  grid-area: heading;
  padding-top: 20px;
  padding-bottom: 20px;
}

.SonnetView__heading{
  @include heading-1;
  color: $dark-grey;
}

.SonnetView__body{
  @include body-1;
  color: $dark-grey;
  margin-top: 10px;
}

.SonnetView__forms {
  grid-area: form;
}

.SonnetView__form {
  min-width: 150px;
}

.SonnetView__formButton {
  @include button-secondary;
  width: 100%;
}


.SonnetView__or {
  @include heading-2;
  color: $blue;
  margin-top: 20px;
}
.SonnetView__sonnet {
  grid-area: sonnet;
}
.SonnetView__sonnetButton{
  @include button-secondary;
}

.SonnetView__sonnetInner {
  @include breakpoint(medium) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.SonnetView__sonnetTitle {
  @include heading-2;
}

.SonnetView__sonnetButtons {
  display: flex;
  justify-content: space-between;
}

.error-border {
  border-color: red;
}

.highlight {
  color: red;
}
