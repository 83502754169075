.Title {
  display: flex;
  justify-content: flex-end;
  margin: 10px 30px;

  h1 {
    @include heading-2;
    position: relative;
    color: $white;
  }
}

.Title__wrapper {
  position: relative;
  padding: 10px 30px;
}

.Title__scroll {
  @include adhere;
  background-color: $blue;
  transform: skewX(25deg);
  @include transition(transform);
  .Title__wrapper:hover &,
  .Title__wrapper:focus &{
    transform: skewX(20deg)
  }
}
